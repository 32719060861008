import React, { useEffect, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames/bind'
import { Row, Col } from 'react-simple-flex-grid'
import Fade from 'react-reveal/Fade'

import { Heading, BlueText, Image, Container } from '_atoms'
import { Hero, QuoteContainer } from '_molecules'
import { Layout } from '_templates'
import { SEO, SolvingRealWorldProblems, EmpoweringStartups, IphoneMock } from '_organisms'
import { useWindowSize } from '_hooks'
import MercadoLibreLogo from '_images/carousel/meli.webp'
import MerckLogo from '_images/carousel/merck.webp'
import OmahaLogo from '_images/carousel/omaha.webp'
import StellarLogo from '_images/carousel/stellar.webp'
import AESLogo from '_images/carousel/aes.webp'
import MoneygramLogo from '_images/carousel/moneygram.webp'
import TapcartLogo from '_images/carousel/tapcart.webp'
import BemisLogo from '_images/carousel/bemis.webp'
import AbInbevLogo from '_images/carousel/abinbev.webp'
import DesignWorksLogo from '_images/carousel/designworks.webp'
import PepsicoLogo from '_images/carousel/pepsico.webp'
import WhyNearshoring from '_components/organisms/why-nearshoring.js'
import How from '_components/organisms/how'
import Brands from '_components/organisms/brands'
import UseCases from '_components/organisms/use-cases'
import TestimonialAes from '_images/testimonials/aes.webp'
import TestimonialAcg from '_images/testimonials/acg.webp'
import TestimonialBemis from '_images/testimonials/bemis.webp'
import TestimonialMercka from '_images/testimonials/mercka.webp'
import TestimonialSkyroam from '_images/testimonials/skyroam.webp'
import TestimonialTapcart from '_images/testimonials/tapcart.webp'
import TestimonialVolo from '_images/testimonials/volo.webp'

import SquareVideo from '../images/iphoneSquareMock.mp4'
import BackgroundStripes from '../images/background-stripes.webp'
import BackgroundStripesWhite from '../images/background-stripes-white.webp'

import styles from './styles.module.css'
import './overrides.css'

const QuoteData = [
  {
    id: '86bae10b-8ba5-5beb-b702-13031e210b28',
    jobDescription: <FormattedMessage id="home.testimonials.jobDescriptionJulia" />,
    user: 'Julia Rodrigues',
    text: {
      internal: {
        content: <FormattedMessage id="home.testimonials.textJulia" />,
      },
    },
    userImage: {
      fixed: {
        width: 64,
        height: 64,
        src: TestimonialAes,
      },
    },
  },
  {
    id: 'fff3c965-159b-503b-8684-42f6e18c9a86',
    jobDescription: <FormattedMessage id="home.testimonials.jobDescriptionNan" />,
    user: 'Nan Jiang',
    text: {
      internal: {
        content: <FormattedMessage id="home.testimonials.textNan" />,
      },
    },
    userImage: {
      fixed: {
        width: 64,
        height: 64,
        src: TestimonialBemis,
      },
    },
  },
  {
    id: '27656cbb-77ae-5804-b1fb-380a59da6e12',
    jobDescription: <FormattedMessage id="home.testimonials.jobDescriptionEric" />,
    user: 'Eric Netsch',
    text: {
      internal: {
        content: <FormattedMessage id="home.testimonials.textEric" />,
      },
    },
    userImage: {
      fixed: {
        width: 64,
        height: 64,
        src: TestimonialTapcart,
      },
    },
  },
  {
    id: '71ba99f8-5647-56dd-9c1b-17ed0fe8c180',
    jobDescription: <FormattedMessage id="home.testimonials.jobDescriptionZach" />,
    user: 'Zach Stockton',
    text: {
      internal: {
        content: <FormattedMessage id="home.testimonials.textZach" />,
      },
    },
    userImage: {
      fixed: {
        width: 64,
        height: 64,
        src: TestimonialAcg,
      },
    },
  },
  {
    id: '491f922a-e78e-547e-8ed6-349d8b8951da',
    jobDescription: <FormattedMessage id="home.testimonials.jobDescriptionMarilia" />,
    user: 'Marilia Campos',
    text: {
      internal: {
        content: <FormattedMessage id="home.testimonials.textMarilia" />,
      },
    },
    userImage: {
      fixed: {
        width: 64,
        height: 64,
        src: TestimonialMercka,
      },
    },
  },
  {
    id: 'b875b5f9-8ae2-54af-b94a-9d084e404771',
    jobDescription: <FormattedMessage id="home.testimonials.jobDescriptionMeredith" />,
    user: 'Meredith Valentine',
    text: {
      internal: {
        content: <FormattedMessage id="home.testimonials.textMeredith" />,
      },
    },
    userImage: {
      fixed: {
        width: 64,
        height: 64,
        src: TestimonialSkyroam,
      },
    },
  },
  {
    id: '44ef034d-8f40-5f47-ace6-e92b276ab95c',
    jobDescription: <FormattedMessage id="home.testimonials.jobDescriptionCollin" />,
    user: 'Collin Goodwin',
    text: {
      internal: {
        content: <FormattedMessage id="home.testimonials.textCollin" />,
      },
    },
    userImage: {
      fixed: {
        width: 64,
        height: 64,
        src: TestimonialVolo,
      },
    },
  },
]

const intlBreakLineValues = {
  strong: (...chunks) => <strong>{chunks}</strong>,
  span: (...chunks) => (
    <span>
      {chunks}
      <br />
    </span>
  ),
  u: (...chunks) => <u>{chunks}</u>,
}

const cx = classNames.bind(styles)

const IndexPage = () => {
  const [width] = useWindowSize()
  const isMobile = width <= 768
  // const allQuotes = data.allContentfulQuote.nodes
  // console.log(allQuotes)
  /* scroll back to top when scrolling on Home, going to another page and coming back */
  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])

  const intl = useIntl()

  const showcaseLogos = [
    { url: MoneygramLogo, label: 'Moneygram' },
    { url: StellarLogo, label: 'Stellar' },
    { url: MercadoLibreLogo, label: 'MercadoLibre' },
    { url: AESLogo, label: 'AES' },
    { url: OmahaLogo, label: 'Omaha' },
    { url: MerckLogo, label: 'Merck' },
    { url: DesignWorksLogo, label: 'DesignWorks' },
    { url: AbInbevLogo, label: 'AbInbev' },
    { url: BemisLogo, label: 'Bemis' },
    { url: PepsicoLogo, label: 'Pepsico' },
    { url: TapcartLogo, label: 'Tapcart' },
  ]

  const customHeroContent = (
    <Row className={styles.heroContent}>
      <Col align="start">
        <Fade distance="25%" top>
          <Heading color="white" bold className={styles.heroTitle}>
            <FormattedMessage values={intlBreakLineValues} id="home.hero.titleStart" />
            <br />
            <BlueText light squared className={styles.heroTitleBlue}>
              <FormattedMessage values={intlBreakLineValues} id="home.hero.blueTitle" />
            </BlueText>
            <br />
            <FormattedMessage values={intlBreakLineValues} id="home.hero.titleEnd" />
            <BlueText light squared className={styles.heroTitleBlue}>
              <span className={styles.squared}>.</span>
            </BlueText>
          </Heading>
        </Fade>
        <Fade distance="25%" bottom>
          <Heading type="h2" color="white" className={styles.heroSubtitle}>
            <FormattedMessage values={intlBreakLineValues} id="home.hero.description" />
          </Heading>
        </Fade>

        {isMobile && (
          <div className={styles.heroPhoneMobileContainer}>
            <Col className={styles.heroPhoneMobile}>
              <Fade distance="25%" bottom>
                <IphoneMock videoSrc={SquareVideo} />
              </Fade>
            </Col>
          </div>
        )}
      </Col>
      {!isMobile && (
        <Col className={styles.heroPhone}>
          <Fade distance="12%" right>
            <IphoneMock videoSrc={SquareVideo} />
          </Fade>
        </Col>
      )}
    </Row>
  )

  const renderLogo = (el, index) => {
    return (
      <div key={`slide-${index}`} data-logo-label={el.label} className={styles.slide}>
        <img src={el.url} alt={el.label} />
      </div>
    )
  }
  const withRepeatedLogos = useMemo(
    () => [
      // This is necessary because every time is showed the repeated list
      // on the same position where was started the animation, it restarts
      // and make an illusion that is infinite
      ...showcaseLogos,
      ...showcaseLogos,
    ],
    [showcaseLogos]
  )

  const onScrollArrowClick = async () => {
    if (typeof window !== 'undefined') {
      await new Promise(resolve =>
        resolve(
          document
            .getElementById('solving-real-problems-section')
            .scrollIntoView({ behavior: 'smooth' })
        )
      )

      await new Promise(resolve => setTimeout(() => resolve(true), 800))

      // Scrolls the 'solving-real-problems-section' into view, then adjusts upwards by 100 pixels.
      // This correction is needed due to the absolute positioned header, which can otherwise
      // obscure the element, creating the impression of an incorrect scroll position.
      window.scrollBy(0, -100)
    }
  }

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'home.seo.title' })}
        description={intl.formatMessage({ id: 'home.seo.description' })}
        thumbnail={intl.formatMessage({ id: 'home.seo.url' })}
      />
      <main className={cx('heroBackground')}>
        <Image
          src={BackgroundStripes}
          alt="Cheesecake background stripes"
          className={styles.heroStripesBackground}
        />
        <Hero
          hasScrollArrow={!isMobile}
          scrollArrowText={intl.formatMessage({ id: 'home.hero.scrollArrowText' })}
          className={styles.hero}
          contentClassName={styles.heroContent}
          scrollArrowButton
          onScrollArrowClick={() => onScrollArrowClick()}
          customContent={customHeroContent}
        />
        <div className={styles.sliderTrackContainer}>
          <div className={styles.sliderContent}>
            <div
              style={{
                width: isMobile
                  ? `calc(33.3vw * ${withRepeatedLogos.length})`
                  : `calc(12.5vw * ${withRepeatedLogos.length})`,
              }}
              className={styles.sliderTrack}
            >
              {withRepeatedLogos.map(renderLogo)}
            </div>
          </div>
        </div>
      </main>
      <SolvingRealWorldProblems />
      <EmpoweringStartups />
      <section className={cx('sub-section', 'safariScrollFlickerFix')}>
        <div className={styles.whatSetUsApartSection}>
          <Image
            src={BackgroundStripesWhite}
            alt="Cheesecake background stripes"
            className={styles.whatSetUsApartSectionStripesBackground}
          />
          <Container>
            <div className={styles.whatSetUsApartSectionText}>
              <Heading className={styles.whatSetUsApartSectionTextTitle} type="h5">
                <FormattedMessage id="home.testimonials.title" />
              </Heading>
              <Heading className={styles.whatSetUsApartSectionTextSubtitle} type="h5">
                <FormattedMessage id="home.testimonials.description" />
              </Heading>
            </div>
            <QuoteContainer
              items={QuoteData}
              isWhite
              isQuoteSignPoppins
              quoteClassName={styles.whatSetUsApartSectionQuote}
              userPhotoClassName={styles.whatSetUsApartSectionQuotePhoto}
              sliderClassName={styles.whatSetUsApartSectionQuoteSlider}
              sliderContainerClassName={styles.whatSetUsApartSectionQuoteSliderContainer}
              iconsClass={styles.whatSetUsApartSectionQuoteSliderIcons}
            />
          </Container>
        </div>
      </section>
      <WhyNearshoring />
      <UseCases />
      <How />
      <Brands />
    </Layout>
  )
}

export default IndexPage
